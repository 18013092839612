

























































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { vxm } from '@/store'
import { Debounce } from 'lodash-decorators'

@Component
export default class OrderPreOrderAgreementsList extends Vue {
  private showOnlyActiveAgreements = false
  isDeleting = false
  selected = []

  headers = []
  agreements = []
  search = ''

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: 15,
    sortBy: [],
  }

  private created() {
    this.showOnlyActiveAgreements = !!localStorage.getItem(this.showOnlyActiveAgreementsKey)
    this.getInitialData()
  }

  private dateFormat(date) {
    return moment(date).format('YYYY-MM-DD')
  }

  private deleteAgreement(item) {
    this.deleteAgreements(item)
  }

  @Watch('showOnlyActiveAgreements')
  private onShowOnlyActiveAgreements() {
    localStorage.setItem(this.showOnlyActiveAgreementsKey, this.showOnlyActiveAgreements ? '1' : '')
    this.getInitialData()
  }

  @Debounce(200)
  private getInitialData() {
    this.$axios
      .get(
        '/v3/order/pre_order/agreement/list/get_initial_data?showOnlyActiveAgreements=' +
          this.showOnlyActiveAgreements,
      )
      .then((response) => {
        this.headers = response.data.data.headers
        this.agreements = response.data.data.agreements
      })
  }

  private deleteAgreements(item) {
    if (confirm(this.$t('c:common:Are you sure you want to delete this item(s)?').toString())) {
      this.isDeleting = true

      let selectedRows
      if (item === null) {
        selectedRows = this.selected
      } else {
        selectedRows = [item]
      }

      const deleteData = {
        selectedRows: selectedRows,
      }
      this.$axios
        .delete('/v3/order/pre_order/agreement/list/delete_agreements', { data: deleteData })
        .then((response) => {
          for (let i = 0; i < selectedRows.length; i++) {
            const index = this.agreements.indexOf(selectedRows[i])
            this.agreements.splice(index, 1)
          }
          vxm.alert.success({
            content: this.$t('c:common:Successfully deleted') as string,
            title: this.$t('c:common:Success') as string,
          })
        })
        .catch((err) => {
          err.response.data.errors.forEach((v, i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.isDeleting = false
        })
    }
  }

  private get showOnlyActiveAgreementsKey() {
    return 'pre-order-agreements-list-showOnlyActiveAgreements'
  }
}
